angular.module('LeasePilot').directive('generateTable', [
  '$rootScope',
  function($rootScope) {
    const rentTableLink = function($scope, element, attrs, ctrl, transclude) {
      let lease;
      let abstract;
      let currTerm;
      let isAmend;
    
      $scope.rowsData = [];
      $scope.Math = window.Math;
      $scope.moment = moment;
    
      transclude($scope, function(clone, $scope) {
        element.append(clone);
        var table = element.find('table');
    
        if ($scope.rent == 'rent') {
          table.attr('hl', 'lease.rentRows');
        }
        if ($scope.rent == 'renewal') {
          table.attr('hl', 'lease.renewalRows');
        }
        if (element.get(0).hasAttribute("list")) {
          table.attr('hl', attrs.var);
        }
      });
      
      $scope.addYears = window.datesHelper.addYears;
      
      $scope.addMonths = window.datesHelper.addMonths;
    
      $scope.addDays = window.datesHelper.addDays;
      
      $scope.firstDayOfMonth = window.datesHelper.firstDayOfMonth;
    
      $scope.isFirstDayOfMonth = window.datesHelper.isFirstDayOfMonth;
    
      $scope.lastDayOfMonth = window.datesHelper.lastDayOfMonth;
    
      $scope.isLastDayOfMonth = window.datesHelper.isLastDayOfMonth;

      $scope.formatDateAsMonthDayYear = window.datesHelper.formatDateAsMonthDayYear;

      $scope.formatDateAsText = window.datesHelper.formatDateAsText;

      $scope.formatDateAs = window.datesHelper.formatDateAs;
    
      if (element.get(0).hasAttribute("list")) {
        $scope.lease = lease = scopeVar.lease;

        if (window.isAbstract) {
          $scope.abstract = abstract = $rootScope.abstract;
        }

        return;
      }
    
      $scope.$on('rentValuesChanged', function(event, data) {
        $scope.calculateRows();
      });
    
      $scope.calculateWithRentBumpPeriods = function() {
        let field;
        let term = 0;
    
        if (!isNaN($scope.specificTerm)) {
          term = Math.max(parseInt($scope.specificTerm), 0);
        }
    
        if ($scope.rent === 'rent') {
          if ($scope.premisesModificationType === 'expansion') {
            field = currTerm.expansion.rentBumps;
          } else if ($scope.premisesModificationType === 'reduction') {
            field = currTerm.reduction.rentBumps;
          } else if ($scope.premisesModificationType === 'relocation') {
            field = currTerm.relocation.rentBumps;
          } else {
            field = currTerm.rentBumps;
          }
        } else if ($scope.rent === 'renewal') {
          field = $scope.lease['renewalRentBumps'][term].periods;
        }
    
        field.forEach((rentPeriod, index) => {
          let startDate;
          let endDate;
          let softStartMonth;
          let softEndMonth;
          let annualizedBaseRent = 0;
          let monthlyBaseRent = 0;
          let isEnd = false;
    
          if (index === 0) {
            softStartMonth = 1;
          } else {
            softStartMonth =
              field[index - 1].begin + field[index - 1].duration;
          }
          field[index].begin = softStartMonth;
    
          var areaSize;
    
          if (lease.hasExtensionOfTerm && lease.rentTableType === 'separate') {
            if ($scope.premisesModificationType === 'expansion') {
              areaSize = lease.expansionPremiseAreaSize;
            } else {
              areaSize = currTerm.premiseAreaSize;
            }
          } else {
            areaSize = currTerm.premiseAreaSize;
          }
    
          if (lease.premisesModType === 'expansion') {
            if (!(lease.hasExtensionOfTerm && lease.rentTableType === 'separate')) {
              areaSize += lease.expansionPremiseAreaSize;
            }
          } else if (lease.premisesModType === 'reduction') {
            areaSize = lease.reductionRemainingAreaSize;
          } else if (lease.premisesModType === 'relocation') {
            areaSize = lease.relocationPremisesAreaSize;
          }
    
          if (rentPeriod.duration) {
            softEndMonth = rentPeriod.begin + rentPeriod.duration - 1;
          } else if ($scope.unknownEnd && index === field.length - 1) {
            isEnd = true;
          }
    
          if (rentPeriod.rentPerSqFoot) {
            annualizedBaseRent = rentPeriod.rentPerSqFoot * areaSize;
            monthlyBaseRent = annualizedBaseRent / 12;
          }
    
          if (isAmend) {
            const isOptionalExtension = $rootScope.hasEditorConfig('optionalExtensionTermLength');
            const isBlendAndExtendNoPremisesModification = $rootScope.hasEditorConfig('optionalExtensionTermLength', 'blendAndExtendNoPremisesModification');
    
            let expirationDate = $scope.lease.existingLeaseExpirationDate;
            if (expirationDate) {
              expirationDate = addDays(expirationDate, 1);
            }
    
            if (
              $scope.lease.premisesModType === 'none' &&
              (!isOptionalExtension || (isOptionalExtension && $scope.lease.hasExtensionOfTerm))
            ) {
              if (
                isOptionalExtension &&
                isBlendAndExtendNoPremisesModification &&
                lease.hasBlendAndExtend
              ) {
                expirationDate = lease.termCommencementDate;
              }
            }
            if (expirationDate) {
              startDate = addMonths(expirationDate, softStartMonth - 1);
              startDate = startDate.toDate();
    
              if (!isEnd) {
                endDate = addDays(addMonths(expirationDate, softEndMonth), -1);
                endDate = endDate.toDate();
              }
            }
          } else {
            let deliveryDate = getDeliveryDateForLease();
    
            if (deliveryDate) {
              startDate = addMonths(deliveryDate, softStartMonth - 1);
              startDate = startDate.toDate();
    
              if (!isEnd && softEndMonth) {
                endDate = addDays(addMonths(deliveryDate, softEndMonth), -1);
                endDate = endDate.toDate();
              }
            }
          }
    
          const row = {
            startDate,
            endDate,
            softStartMonth,
            softEndMonth,
            annualizedBaseRent,
            monthlyBaseRent,
            rentPerSqFoot: rentPeriod.rentPerSqFoot,
          };
    
          if ($scope.unknownEnd) {
            row.isEnd = isEnd;
          }
    
          $scope.rowsData.push(row);
        });
      };
    
      $scope.calculateWithNormalRentPeriods = function() {
        addBaseRows();
    
        if ($scope.rent === 'renewal' && !$scope.specificTerm) {
          separateRenewalsYears();
        }
    
        if ($scope.rent === 'rent' && lease.leaseMonths) {
          addLeaseMonths();
        }
    
        if (currTerm.hasFreeRent && currTerm.freeRent) {
          if ($scope.rent === 'rent') {
            addFreeRentMonthsToBaseRent();
          }
    
          if ($scope.rent === 'renewal' && currTerm.hasFreeRentAddedToTerm) {
            addFreeRentMonthsToRenewal();
          }
        }
    
        if (
          currTerm.hasFreeRent &&
          currTerm.freeRentPeriods &&
          currTerm.freeRentPeriods.length
        ) {
          addFlexibleFreeRentMonthsToBaseRent();
        }
    
        if ($scope.rent == 'rent') {
          if (lease.hasRentAbatement) {
            addAbatementDates();
          }
        }
    
        if ($scope.rent == 'renewal') {
          increaseDatesForRenewal();
        }
    
        if ($scope.consolidated) {
          consolidatedRows();
        }
    
        normalize();
      };
    
      $scope.calculateRows = function() {
        $scope.lease = lease = scopeVar.lease;
        isAmend = lease.form.name === 'Amendment';
        currTerm = Number.isInteger(parseInt($scope.termIndex)) ? lease.terms[parseInt($scope.termIndex)] : lease;
        $scope.rowsData = [];
    
        let isFlexibleFreeRent;
        if ($scope.rent === 'rent') {
          isFlexibleFreeRent = (currTerm.rentInputType === 'flexible' || currTerm.rentInputType === 'flexible-amendment');
        } else if ($scope.rent === 'renewal') {
          let term = 0;
    
          if (!isNaN($scope.specificTerm)) {
            term = Math.max(parseInt($scope.specificTerm), 0);
          }
    
          if ($scope.lease.renewalOptionRent === 'fixed') {
            isFlexibleFreeRent =
              $scope.lease.renewalInfo[term] &&
              $scope.lease.renewalInfo[term].renewalInputType === 'flexible';
          }
        }
    
        if (isFlexibleFreeRent) {
          $scope.calculateWithRentBumpPeriods();
        } else {
          $scope.calculateWithNormalRentPeriods();
        }
    
        setTimeout(()=>{
          $rootScope.findAppScope().compileVisibleFreeTexts();
        });
      };
    
      function addYears(date, years) {
        let futureDate = moment(date).add(years, 'years');
    
        // If the date is the last day of the month, we want to stay on it,
        // even after adding years. this is an edge-case to handle adding years to February
        // 28th that takes you to a leap year where the end of February is the 29th
        if (
          moment(date).date() ===
          moment(date)
            .endOf('month')
            .date()
        ) {
          futureDate.endOf('month').startOf('day');
        }
    
        return futureDate;
      }
    
      function addMonths(date, months) {
        let futureDate = moment(date).add(months, 'months');
    
        // If the date is the last day of the month, we want to stay on it,
        // even after adding months.
        if (
          moment(date).date() ===
          moment(date)
            .endOf('month')
            .date()
        ) {
          futureDate.endOf('month').startOf('day');
        }
    
        return futureDate;
      }
    
      function addDays(date, days) {
        var futureDate = moment(date).add(days, 'days');
        return futureDate;
      }
    
      
    
      //1. add base rows
      function addBaseRows() {
        var basePeriods;
        switch ($scope.rent) {
          case 'rent':
            basePeriods = currTerm.rentPeriods || [];
            basePeriods = _.map(basePeriods, period => {
              period.rentInputType = currTerm.rentInputType;
              return period;
            });
            break;
          case 'renewal':
            if (!lease.renewalInfo) {
              basePeriods = [];
            } else if (!isNaN($scope.specificTerm)) {
              $scope.specificTerm = _.toNumber($scope.specificTerm);
    
              basePeriods = [];
              if (lease.renewalInfo[$scope.specificTerm]) {
                basePeriods =
                  lease.renewalInfo[$scope.specificTerm].renewalRentPeriods;
                basePeriods = _.map(basePeriods, period => {
                  period.rentInputType =
                    lease.renewalInfo[$scope.specificTerm].renewalInputType;
                  return period;
                });
              }
            } else {
              basePeriods = getAllRenewalsPeriods();
            }
            break;
          default:
            basePeriods = [];
        }
    
        var firstYear;
        // exclude base-rentTable
        if ($scope.excludeBaseRent) {
          firstYear = 1;
        } else {
          firstYear = 0;
        }
    
        for (let i = firstYear; i < basePeriods.length; i++) {
          var period = basePeriods[i];
          var startDate,
            endDate,
            psf,
            areaSize,
            yearIndex,
            annualRent,
            monthlyRent,
            endMonth;
          if (isAmend) {
            if (
              lease.hasMonthToMonth && 
              $rootScope.hasEditorConfig('optionalExtensionTermLength', 'startRentTableAtMonthToMonthExpiration')
            ) {
              startDate = addYears(
                addDays(lease.monthToMonthEnd, 1),
                i,
              );
            } else {
              if (lease.existingLeaseExpirationDate) {
                startDate = addYears(
                  addDays(lease.existingLeaseExpirationDate, 1),
                  i,
                );
              }
            }
          } else {
            //(isLease)
            startDate = addYears(getDeliveryDateForLease(), i);
          }
    
          endDate = addDays(addYears(startDate, 1), -1);
          areaSize = getAreaSize();
          endMonth = (i + 1) * 12;
          //should be fixed - `rentPerSqFoot` should be always the annual.
          switch (period.rentInputType) {
            case 'manual': // Per Square Foot
            case 'automatic': // Per Square Foot with Annual Escalation
              psf = period.rentPerSqFoot;
              annualRent = period.rentPerSqFoot * areaSize;
              monthlyRent = (period.rentPerSqFoot / 12) * areaSize;
              break;
            case 'fixed-no-escalation': // Fixed-Monthly No Escalation
              period = basePeriods[0];
            case 'fixed': // Fixed - Monthly
              psf = (period.totalRent * 12) / areaSize;
              annualRent = period.totalRent * 12;
              monthlyRent = period.totalRent;
              break;
          }
          if (!lease.leaseYears && lease.leaseMonths) {
            endMonth = 0;
          }
    
          if (
            $scope.rent === "renewal" && 
            $scope.specificTerm !== undefined && 
            ($scope.specificTermContinueDates)
          ) {
            startDate = addYears(startDate, $scope.specificTerm * lease.renewalTerm);
            endDate = addYears(endDate, $scope.specificTerm * lease.renewalTerm);
          }
    
          $scope.rowsData.push({
            startDate: startDate?.toDate(),
            endDate: endDate?.toDate(),
            psf: parseFloat(psf),
            calculatedPsf: parseFloat(psf),
            annualRent: parseFloat(annualRent),
            monthlyRent: parseFloat(monthlyRent),
            calculatedAnnualRent: parseFloat(annualRent),
            areaSize: parseFloat(areaSize),
            startYearIndex: i + 1,
            endYearIndex: i + 1,
            startMonth: (i + 1) * 12 - 11,
            endMonth: endMonth,
          });
        }
      }
    
      //2. separate periods to renewals
      function separateRenewalsYears() {
        var renewal;
        for (var i = 0; i < $scope.rowsData.length; i++) {
          var row = $scope.rowsData[i];
          renewal = parseInt(i / lease.renewalTerm);
          row.renewalIndex = renewal;
        }
      }
    
      //3. add lease Months
      function addLeaseMonths() {
        //add months to the last period
        var lastPeriod = $scope.rowsData[$scope.rowsData.length - 1];
    
        if (!lastPeriod) {
          return;
        }
    
        var endMonth = $scope.rowsData.length > lease.leaseYears ? lastPeriod.endMonth - 12 : lastPeriod.endMonth;
        if (lease.leaseYears ==  0) {
          endMonth += 12;
        }
    
        lastPeriod.endMonth = endMonth + lease.leaseMonths;
        if (isAmend) {
          if ($rootScope.hasEditorConfig('optionalExtensionTermLength', 'addRentRowForPartialYear')) {
            lastPeriod.endDate = addDays(addMonths(
              $scope.rowsData.length > lease.leaseYears ? lastPeriod.startDate : lastPeriod.endDate,
              lease.leaseMonths,
            ), -1).toDate();
          } else {
            if (lease.leaseYears > 0) {
              lastPeriod.endDate = addMonths(
                $scope.rowsData.length > lease.leaseYears ? lastPeriod.startDate : lastPeriod.endDate,
                lease.leaseMonths
              ).toDate();
            } else {
              lastPeriod.endDate = addMonths(addYears(lastPeriod.endDate, -1), lease.leaseMonths).toDate();
            }
          }
        } else {
          lastPeriod.endDate = addDays(addMonths(
            $scope.rowsData.length > lease.leaseYears ? lastPeriod.startDate : lastPeriod.endDate,
            lease.leaseMonths,
          ), -1).toDate();
        }
      }
    
      //4. add free rent months
      function addFreeRentMonthsToBaseRent() {
        if ($scope.rowsData.length === 0) {
          return;
        }
    
        var freeRentRow = {
          isFree: true,
          startYearIndex: 1,
          endYearIndex: 1,
          psf: 0,
          annualRent: 0,
          monthlyRent: 0,
        };
    
        if (currTerm.freeRentExtendedAt == 'beginning') {
          // extended lease year at beginning of term
          if (!$scope.excludeBaseRent) {
            freeRentRow.startDate = $scope.rowsData[0].startDate;
            freeRentRow.endDate = addDays(
              addMonths($scope.rowsData[0].startDate, currTerm.freeRent),
              -1,
            ).toDate();
          }
    
          if ($scope.addRowForFreeRent) {
            $scope.rowsData.unshift(freeRentRow);
          }
    
          if (currTerm.hasFreeRentAddedToTerm) {
            let startIndex = $scope.excludeBaseRent ? 0 : 1;
    
            for (var i = startIndex; i < $scope.rowsData.length; i++) {
              var period = $scope.rowsData[i];
              var startDate = moment(period.startDate);
              var endDate = moment(period.endDate);
              period.startDate = addMonths(
                startDate,
                currTerm.freeRent,
              ).toDate();
              period.endDate = addMonths(endDate, currTerm.freeRent).toDate();
            }
          } else {
            if (!$scope.excludeBaseRent) {
              if ($scope.rowsData[1]) {
                $scope.rowsData[1].startDate = addMonths(
                  $scope.rowsData[1].startDate,
                  currTerm.freeRent,
                ).toDate();
              }
            }
          }
        } else if (currTerm.freeRentExtendedAt == 'end') {
          // extended lease year at end of term
          var lastRow = $scope.rowsData[$scope.rowsData.length - 1];
    
          freeRentRow.startYearIndex += lastRow.startYearIndex;
          freeRentRow.endYearIndex += lastRow.endYearIndex;
    
          if (currTerm.hasFreeRentAddedToTerm) {
            freeRentRow.startDate = addDays(lastRow.endDate, 1).toDate();
            freeRentRow.endDate = addMonths(
              lastRow.endDate,
              currTerm.freeRent,
            ).toDate();
          } else {
            freeRentRow.startDate = addDays(
              addMonths(lastRow.endDate, -currTerm.freeRent),
              1,
            ).toDate();
            freeRentRow.endDate = moment(lastRow.endDate).toDate();
    
            lastRow.endDate = addMonths(
              lastRow.endDate,
              -currTerm.freeRent,
            ).toDate();
          }
    
          if (
            $scope.addRowForFreeRent &&
            currTerm.hasFreeRentAddedToTerm
          ) {
            if (
              currTerm.leaseMonths === 0 ||
              !$rootScope.hasEditorConfig('termLength', 'addRentRowForPartialYear')
            ) {
              $scope.rowsData.push(freeRentRow);
            }
          }
        }
      }
    
      function addFreeRentMonthsToRenewal() {
        if ($scope.rowsData.length === 0) {
          return;
        }
    
        for (var i = 0; i < $scope.rowsData.length; i++) {
          const currentRow = $scope.rowsData[i];
          currentRow.startDate = addMonths(
            currentRow.startDate,
            currTerm.freeRent,
          );
          currentRow.endDate = addMonths(currentRow.endDate, currTerm.freeRent);
        }
      }
    
      //5. push abatement dates
      function addAbatementDates() {
        var periods = [];

        if ($scope.addRowForFreeRent == false) {
          for (var i = 0; i < $scope.rowsData.length; i++) {
            var period = $scope.rowsData[i];
            var startDate = moment(period.startDate);
            var endDate = moment(period.endDate);
            var freeDates = lease.abatementDates;

            for (var j = 0; j < freeDates.length; j++) {
              var freeMonth = moment(freeDates[j].date);

              if (freeMonth.isBetween(startDate, endDate)) {
                period.hasFreeRent = true;
              }
            }
          }
        } else {
          for (var i = 0; i < $scope.rowsData.length; i++) {
            var period = $scope.rowsData[i];
      
            var startDate = moment(period.startDate);
            var endDate = moment(period.endDate);
      
            //extended lease year at beginning of term
            if (currTerm.hasFreeRentAddedToTerm) {
              if (currTerm.freeRentExtendedAt == 'beginning') {
                endDate = addMonths(endDate, lease.abatementDates.length);
                if (i > 0) {
                  startDate = addMonths(startDate, lease.abatementDates.length);
                }
              } else if (currTerm.freeRentExtendedAt == 'end') {
                if (i == lease.leaseYears - 1) {
                  endDate = addMonths(endDate, lease.abatementDates.length);
                }
              }
            }
      
            var freeDates = _.sortBy(
              _.filter(lease.abatementDates, function(date) {
                return (
                  moment(date.date).isSame(startDate) ||
                  moment(date.date).isBetween(startDate, endDate)
                );
              }),
              'date',
            );
      
            for (var j = 0; j < freeDates.length; j++) {
              var freeMonth = freeDates[j].date;
              //push period of year
              if (startDate.isBefore(freeMonth)) {
                var obj = _.clone($scope.rowsData[i]);
                obj.startDate = startDate.toDate();
                obj.endDate = addDays(freeMonth, -1).toDate();
                obj.isFree = false;
                obj.calculatedPsf = currTerm.hasFreeRentAddedToTerm
                  ? obj.psf
                  : (obj.psf / 12) * (12 - freeDates.length);
                obj.calculatedAnnualRent = currTerm.hasFreeRentAddedToTerm
                  ? obj.annualRent
                  : (obj.annualRent / 12) * (12 - freeDates.length);
                periods.push(obj);
              }
      
              //push free rent period
              var obj = _.clone($scope.rowsData[i]);
              obj.startDate = moment(freeMonth).toDate();
              obj.endDate = moment(freeMonth)
                .endOf('month')
                .startOf('day')
                .toDate();
              obj.isFree = true;
              obj.isFirst = !_.some(periods, function(e) {
                return e.isFirst == true;
              });
              obj.psf = 0;
              obj.annualRent = 0;
              obj.monthlyRent = 0;
              periods.push(obj);
      
              startDate = addDays(
                moment(freeMonth)
                  .endOf('month')
                  .startOf('day'),
                1,
              );
            }
      
            //push the last period of year
            if (startDate.isBefore(endDate) || !startDate.isValid()) {
              var obj = _.clone($scope.rowsData[i]);
              obj.startDate = startDate.toDate();
              obj.endDate = endDate.toDate();
              obj.isFree = false;
              obj.calculatedPsf = currTerm.hasFreeRentAddedToTerm
                ? obj.psf
                : (obj.psf / 12) * (12 - freeDates.length);
              obj.calculatedAnnualRent = currTerm.hasFreeRentAddedToTerm
                ? obj.annualRent
                : (obj.annualRent / 12) * (12 - freeDates.length);
              periods.push(obj);
            }
          }

          $scope.rowsData = periods;
        }
      }
    
      function splitRowsDataToMonths() {
        var baseStartDate = $scope.rowsData[0].startDate;
        var monthNum = 1;
        var monthsRowsData = _.chain($scope.rowsData)
          .cloneDeep()
          .flatMap(function(row, rowIndex) {
            var monthsInRow = row.endMonth - row.startMonth + 1;
            var monthsList = _.times(monthsInRow, function(i) {
              var obj = _.clone(row);
              obj.originalRowNumber = rowIndex;
              obj.startMonth = monthNum;
              obj.startDate = addMonths(baseStartDate, monthNum - 1).toDate();
              obj.flexiblePeriod = -1;
              monthNum++;
              return obj;
            });
            return monthsList;
          })
          .value();
        return monthsRowsData;
      }
    
      function calculatePrecentFreeMonths(monthsRowsData) {
        if ($rootScope.hasEditorConfig('freeRent', 'flexible')) {
          _.each(currTerm.freeRentPeriods, function(period, periodIndex) {
            if (
              !period.beginIn ||
              !period.duration ||
              (!period.percentFree && !period.amount)
            ) {
              return;
            }
            var endIn = period.beginIn + period.duration;
    
            if (period.percentFree) {
              var discountPercent = (100 - period.percentFree) / 100;
              for (var i = period.beginIn; i < endIn; i++) {
                monthsRowsData[i - 1].monthlyRent *= discountPercent;
                monthsRowsData[i - 1].psf *= discountPercent;
                monthsRowsData[i - 1].flexiblePeriod = periodIndex;
              }
            } else if (period.amount) {
              for (var i = period.beginIn; i < endIn; i++) {
                monthsRowsData[i - 1].monthlyRent -= period.amount;
                monthsRowsData[i - 1].flexiblePeriod = periodIndex;
              }
            }
          });
        }
    
        return monthsRowsData;
      }
    
      function joinMonthsToYears(monthsRowsData) {
        var combinedRowsData = [];
        var groupMonthsRowsData = _.groupBy(
          monthsRowsData,
          'originalRowNumber',
        );
    
        _.each(groupMonthsRowsData, function(months) {
          var currMonth = months[0];
          combinedRowsData.push(currMonth);
          _.each(months, function(month) {
            if (month.flexiblePeriod != currMonth.flexiblePeriod) {
              currMonth = month;
              combinedRowsData.push(currMonth);
            }
          });
        });
    
        _.each(combinedRowsData, function(item, i) {
          if (i < combinedRowsData.length - 1) {
            combinedRowsData[i].endDate = addDays(
              combinedRowsData[i + 1].startDate,
              -1,
            ).toDate();
            combinedRowsData[i].endMonth =
              combinedRowsData[i + 1].startMonth - 1;
          }
        });
    
        return combinedRowsData;
      }
    
      function addFlexibleFreeRentToTerm() {
        var freeRentLength = _.sumBy(currTerm.freeRentPeriods, function(period) {
          return period.duration || 0;
        });
    
        if (currTerm.freeRentExtendedAt == 'beginning') {
          var firstRow = $scope.rowsData[0];
          firstRow.endDate = addMonths(
            firstRow.endDate,
            freeRentLength,
          ).toDate();
          firstRow.endMonth += freeRentLength;
          for (var i = 1; i < $scope.rowsData.length; i++) {
            var period = $scope.rowsData[i];
            period.startDate = addMonths(
              period.startDate,
              freeRentLength,
            ).toDate();
            period.endDate = addMonths(
              period.endDate,
              freeRentLength,
            ).toDate();
            period.startMonth += freeRentLength;
            period.endMonth += freeRentLength;
          }
        } else if (currTerm.freeRentExtendedAt == 'end') {
          // extended lease year at end of term
          var lastRow = $scope.rowsData[$scope.rowsData.length - 1];
          lastRow.endDate = addMonths(
            lastRow.endDate,
            freeRentLength,
          ).toDate();
          lastRow.endMonth += freeRentLength;
        }
      }
    
      //6. add flexible free rent months to base rent
      function addFlexibleFreeRentMonthsToBaseRent() {
        if ($scope.rowsData.length === 0) {
          return;
        }
        if (currTerm.hasFreeRentAddedToTerm) {
          addFlexibleFreeRentToTerm();
        }
        if ($scope.addRowForFreeRent) {
          var monthsRowsData = splitRowsDataToMonths();
          monthsRowsData = calculatePrecentFreeMonths(monthsRowsData);
          $scope.rowsData = joinMonthsToYears(monthsRowsData);
        }
      }
    
      //7. increase dates For Renewal
      function increaseDatesForRenewal() {
        if (
          !$rootScope.hasEditorConfig('optionalExtensionTermLength') || 
          ($rootScope.hasEditorConfig('optionalExtensionTermLength') && $scope.lease.hasExtensionOfTerm
        )) {
          for (var i = 0; i < $scope.rowsData.length; i++) {
            var row = $scope.rowsData[i];
            row.startDate = addYears(row.startDate, lease.leaseYears).toDate();
            row.endDate = addYears(row.endDate, lease.leaseYears).toDate();
            if (lease.leaseMonths) {
              row.startDate = addMonths(
                row.startDate,
                lease.leaseMonths,
              ).toDate();
              row.endDate = addMonths(row.endDate, lease.leaseMonths).toDate();
            }
          }
        }
      }
    
      //8. consolidate rent rows
      function consolidatedRows() {       
        for (var i = 0; i < $scope.rowsData.length; i++) {
          $scope.rowsData[i].isConsolidated = false;
        }

        for (var i = $scope.rowsData.length - 1; i > 0; i--) {
          var period = $scope.rowsData[i];
          var prevPeriod = $scope.rowsData[i - 1];
          
          if (
            period.annualRent == prevPeriod.annualRent &&
            period.renewalIndex == prevPeriod.renewalIndex
          ) {
            prevPeriod.endDate = period.endDate;
            prevPeriod.endMonth = period.endMonth;
            prevPeriod.endYearIndex = period.endYearIndex;
            prevPeriod.isConsolidated = true;
            $scope.rowsData.splice(i, 1);
          }
        }
      }
    
      //last
      function normalize() {
        for (var i = 0; i < $scope.rowsData.length; i++) {
          if (!moment($scope.rowsData[i].startDate).isValid()) {
            $scope.rowsData[i].startDate = null;
          }
          if (!moment($scope.rowsData[i].endDate).isValid()) {
            $scope.rowsData[i].endDate = null;
          }
        }
      }
    
      //
      function getAllRenewalsPeriods() {
        var periods = [];
        for (var i = 0; i < $scope.lease.renewalInfo.length; i++) {
          periods = _.concat(
            periods,
            $scope.lease.renewalInfo[i].renewalRentPeriods,
          );
          periods = _.map(periods, period => {
            period.rentInputType = lease.renewalInfo[i].renewalInputType;
            return period;
          });
        }
        return periods;
      }
    
      function getDeliveryDateForLease() {
        var deliveryDate;
    
        if (lease.tenantImprovements && lease.tenantImprovements.type) {
          if ($rootScope.hasEditorConfig('commencementDate')) {
            deliveryDate = currTerm.termCommencementDate;
          } else {
              switch (lease.tenantImprovements.type) {
                case 'landlord':
                  deliveryDate = currTerm.estimatedCommencementDate;
                  break;
                case 'tenant':
                  deliveryDate = currTerm.estimatedCommencementDate;
                  break;
                case 'none':
                  deliveryDate = currTerm.termCommencementDate;
                  break;
              }
          }
        } else if (lease.rentCommenceAt) {
          deliveryDate = lease.rentCommenceAt;
        }
    
        return deliveryDate;
      }
    
      function getAreaSize() {
        var areaSize = currTerm.premiseAreaSize;
    
        if (lease.premisesModType === 'expansion') {
          areaSize += lease.expansionPremiseAreaSize;
        } else if (lease.premisesModType === 'reduction') {
          areaSize = lease.reductionRemainingAreaSize;
        } else if (lease.premisesModType === 'relocation') {
          areaSize = lease.relocationPremisesAreaSize;
        }
    
        return areaSize;
      }
    
      $scope.init = (function() {
        $scope.calculateRows();
      })();
    };
    
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        rent: '@',
        specificTerm: '=',
        specificTermContinueDates: '=',
        termIndex: '@',
        consolidated: '@',
        excludeBaseRent: '=',
        addRowForFreeRent: '=', //insert new row for `lease.freeRent`
        unknownEnd: '=',
        premisesModificationType: '@',
      },
      link: rentTableLink,
    };
  },
]);
